import 'antd/dist/antd.css';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AdminRoutes from './components/admin/routes/AdminRoutes';
import LoginPage from './components/admin/pages/Login';
import { Spinner } from './components/_reusable/Spinner';

const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={Spinner}>
          <Switch>
            <Route path="/login" component={LoginPage} exact />
            <Route path="/" component={AdminRoutes} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
