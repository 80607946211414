import { Button, Form, Input, Select, Upload, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ArticleService, { ArticleType } from '../../../../services/ArticleService';
import CategoryDropDown from '../../_reusable/CategoryDropdown';
import Editor from '../../_reusable/Editor';
import PageContent from '../../_reusable/PageContent';
import SubcategoryDropDown from '../../_reusable/SubcategoryDropdown';
import { Confirm } from '../../_reusable/TableOption';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ImageUploader from '../../_reusable/ImageUploader';
import TagDropDown from '../../_reusable/TagDropdown';
import moment from 'moment';
import FileUploader from '../../_reusable/FileUploader';

export const parseImageJSON = (str: any, callBack: (jsonData: any) => string) => {
  try {
    return callBack(JSON.parse(str));
  } catch (e) {
    return str;
  }
}

interface MatchParams {
  id?: string
}

const ArticleForm: React.FC<RouteComponentProps<MatchParams>> = (props) => {
  const [categoryId, setCategoryId] = useState<number>();
  const [formData, setFormData] = useState<ArticleType>();
  const [mediaType, setMediaType] = useState<string>();
  const [form] = Form.useForm();
  const editId = props.match.params.id && parseInt(props.match.params.id);

  useEffect(() => {
    if (editId) {
      ArticleService.get(editId)
        .then((data) => {
          data.tagIds = data.tags?.map(tag => tag.id);
          data.publishDate = undefined;
          data.mediaType = data.category?.mediaType;
          setCategoryId(data.categoryId);
          setMediaType(data.mediaType);
          form.setFieldsValue({
            ...data,
            publishDate: moment(data.publishDate),
            hqImage: parseImageJSON(data.mediaUrl, (jsonData) => jsonData.hqImage),
            thumbnail: parseImageJSON(data.mediaUrl, (jsonData) => jsonData.thumbnail),
            pdfFile: parseImageJSON(data.mediaUrl, (jsonData) => jsonData.pdfFile),
          });
        });
    }
  }, [editId])

  const checkPriority = (rule: any, value: any) => {
    if (form.getFieldValue("priority") === 1 && !value) {
      return Promise.reject("Main news article needs HQ image");
    }

    return Promise.resolve();
  };

  return (
    <PageContent hasBack title={editId ? 'Edit Article' : 'Create Article'} >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={values => {
          const { hqImage, thumbnail, pdfFile, ...data } = values;

          setFormData({
            ...data,
            tags: data.tagIds?.map((tagId: number) => tagId),
            mediaUrl: JSON.stringify({ hqImage, thumbnail, pdfFile })
          } as ArticleType)
        }}>
        <Form.Item
          label="Media Type"
          name="mediaType"
          wrapperCol={{
            xs: {
              span: 6
            }
          }}
          rules={[{ required: true }]} >
          <Select placeholder="Select Media Type"
            onChange={(value: string) => {
              form.setFieldsValue({
                hqImage: undefined
              });
              setMediaType(value);
            }}>
            <Select.Option value="image">{'Image'}</Select.Option>
            <Select.Option value="video">{'Video'}</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Priority"
          name="priority"
          wrapperCol={{
            xs: {
              span: 6
            }
          }} >
          <Select placeholder="Select Priority">
            <Select.Option value={1}>Main Home Article</Select.Option>
            <Select.Option value={2}>Main Headline Article</Select.Option>
            <Select.Option value={3}>Small Article</Select.Option>
            <Select.Option value={99}>Normal Article</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Thumbnail"
          name="thumbnail"
          rules={[{ required: true }]}
        >
          <ImageUploader placeholder="Upload 480x270" />
        </Form.Item>

        <Form.Item
          label="HQ Image / Video url"
          name="hqImage"
          rules={[{ validator: checkPriority }]}
        >
          {!mediaType || mediaType === 'image'
            ? <ImageUploader placeholder="Upload 1280x720" />
            : <Input placeholder="Paste video URL here" />}
        </Form.Item>

        <Form.Item
          label="PDF"
          name="pdfFile"
        >
          <FileUploader placeholder="Upload PDF" />
        </Form.Item>

        <CategoryDropDown
          label="Category"
          mediaType={mediaType}
          rules={[{ required: true }]}

          onChange={(value: number) => {
            setCategoryId(value);
            form.setFieldsValue({
              subcategoryId: undefined
            });
          }}
          wrapperCol={{
            xs: {
              span: 6
            }
          }} />

        <SubcategoryDropDown
          label="Subcategory"
          categoryId={categoryId}
          wrapperCol={{
            xs: {
              span: 6
            }
          }} />

        <TagDropDown
          label="Tags" />

        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Author"
          name="author"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Updated Date"
          name="publishDate"
          rules={[{ required: true }]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          label="Intro"
          name="intro"
          rules={[{ required: true }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
        >
          <Editor />
        </Form.Item>

        <Form.Item wrapperCol={{
          xs: {
            span: 4,
            offset: 0,
          },
          sm: {
            span: 4,
            offset: 4,
          },
        }}>
          <Button type="primary" htmlType="submit">
            {editId ? 'Update' : 'Create'}
          </Button>
        </Form.Item>
      </Form>
      {formData && <Confirm
        label="Submit"
        message="Are you sure you want to submit?"
        onConfirm={() => (
          !editId
            ? ArticleService.save(formData)
            : ArticleService.edit({ ...formData, id: editId }))
          .then(() => window.location.reload())}
        onCancel={() => setFormData(undefined)} />}
    </PageContent >
  );
};

export default ArticleForm;
