import { DownOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Dropdown, Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import LogoNew from '../../../assets/images/logo.jpg';
import UserService from '../../../services/UserService';
import ArticleForm from '../pages/article/ArticleForm';
import ArticleList from '../pages/article/ArticleList';
import TagList from '../pages/category copy/TagList';
import CategoryList from '../pages/category/CategoryList';
import DashboardPage from '../pages/Dashboard';
import SubcategoryList from '../pages/subcategory/SubcategoryList';
import ChangePassword from '../pages/user/ChangePassword';
import UserList from '../pages/user/UserList';
import AdminSideBar from '../scaffolding/AdminSideBar';
import './style.scss';

interface props { }

const AdminRoutes: React.FC<props> = () => {
  const [sidebarCollaspe, setSidebarCollaspe] = useState<boolean>();
  const toggleSidebar = () => setSidebarCollaspe(!sidebarCollaspe);
  const history = useHistory();

  const handleClick = ({ key }: { key: string }) => {
    switch (key) {
      case '3':
        UserService.logout(history);
        break;
    }
  };
  return (
    <Layout>
      <Switch>
        <Route path="/" >
          <Layout.Sider
            theme="light"
            width={256}
            style={{ height: '100vh' }}
            collapsible collapsed={sidebarCollaspe}>
            <div className="logo-container">
              <img src={LogoNew} width="auto" height="80%" alt="Company Logo" />
            </div>
            <AdminSideBar />
          </Layout.Sider>
          <Layout>
            <Layout.Header>
              <div className="header-container">
                <div style={{ float: 'left' }}>
                  {React.createElement(
                    sidebarCollaspe
                      ? MenuUnfoldOutlined
                      : MenuFoldOutlined, {
                    className: 'trigger',
                    onClick: toggleSidebar,
                  })}
                </div>
                <div style={{ float: 'right' }}>
                  <Dropdown overlay={
                    <Menu onClick={handleClick}>
                      <Menu.Item key="1">
                        <Link to="/change-password">Change Password</Link>
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item key="3"> <LogoutOutlined /> Logout</Menu.Item>
                    </Menu>} trigger={['click']}>
                    <a className="nav-username"
                      onClick={e => e.preventDefault()}>
                      <span className="icon-user">
                        {UserService.getLoggedInUserName(history)
                          .split(' ')
                          .map(splitStr => splitStr.charAt(0))
                          .join('')}
                      </span>
                      <span>
                        {UserService.getLoggedInUserName(history)}<DownOutlined />
                      </span>
                    </a>
                  </Dropdown>
                </div>
              </div>
            </Layout.Header>
            <Layout.Content style={{ height: 'calc(100vh - 64px)' }}>
              <Switch>
                <Route path="/dashboard" component={DashboardPage} />
                <Route path="/change-password" component={ChangePassword} />
                <Route path="/article/list" component={ArticleList} />
                <Route path="/article/:id" component={ArticleForm} />
                <Route path="/user/list" component={UserList} />
                <Route path="/tag/list" component={TagList} />
                <Route path="/category/list" component={CategoryList} />
                <Route path="/subcategory/list" component={SubcategoryList} />
              </Switch>
            </Layout.Content>
          </Layout>
        </Route>
      </Switch>
    </Layout>
  );
};

export default AdminRoutes;
