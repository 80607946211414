import { Form, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import React, { useEffect, useState } from 'react';
import TagService, { TagType } from '../../../services/TagService';

interface TagDropDownType {
  tagId?: number,
  onChange?: (value: number) => void,
  label?: string,
  rules?: Rule[],
  wrapperCol?: any
}

const TagDropDown: React.FC<TagDropDownType> = (props) => {
  const { onChange, tagId, ...otherProps } = props;
  const [tagList, setTagList] = useState<TagType[]>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    TagService.getAll()
      .then((list: TagType[]) => {
        setLoading(false);
        setTagList(list);
      }).catch(error => setLoading(false));
  }, []);

  return (
    <Form.Item
      {...otherProps}
      name="tagIds"
      hasFeedback
      validateStatus={loading ? "validating" : ""}>
      <Select
        mode="multiple"
        placeholder={tagList
          ? "Select Tag"
          : loading
            ? "Loading Tag"
            : "No Tag Available"}
        onChange={onChange}
        optionFilterProp="children"
        showSearch
        notFoundContent={null}
        defaultActiveFirstOption={false}>
        {tagList &&
          tagList.map((tag, i) => (
            <Select.Option
              key={"tag" + i}
              value={tag.id} >
              {tag.title}
            </Select.Option>))}
      </Select>
    </Form.Item>
  );
};

export default TagDropDown;
