import React from 'react';
import { Card, PageHeader, Button } from 'antd';
import { BackwardOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

interface PageContentType {
  title: string,
  extra?: any,
  hasBack?: boolean
}

const PageContent: React.FC<PageContentType> = (props) => {
  const history = useHistory();
  return (
    <Card
      title={<span>{
        props.hasBack &&
        <Button type="link" onClick={()=> history.goBack()}>
          <ArrowLeftOutlined />
        </Button>}
        {props.title}
      </span>}
      style={{ margin: 15 }}
      extra={props.extra}>
      {props.children}
    </Card>
  );
};

export default PageContent;
