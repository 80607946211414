import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch, Table, Button, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageContent from '../../_reusable/PageContent';
import TableOption from '../../_reusable/TableOption';
import UserService, { UserType } from '../../../../services/UserService';
import Modal from 'antd/lib/modal/Modal';
import { Confirm } from '../../_reusable/TableOption';

const UserList: React.FC = () => {
  const [userList, setUserList] = useState<UserType[]>();
  const [editModalId, setEditModalId] = useState<number>();
  const [addModal, setAddModal] = useState<boolean>();
  const history = useHistory();
  const [form] = Form.useForm();
  const updateUser = (
    id: number,
    data: any
  ) => userList &&
    setUserList(userList
      .map(userData => userData.id === id ? { ...userData, ...data } : userData));

  useEffect(() => {
    UserService.getAll()
      .then((userList: UserType[]) => {
        setUserList(userList);
      }).catch(e => e)
  }, []);

  const deleteUser = (id: number) => {
    UserService.del(id)
      .then(() => {
        setUserList(userList &&
          userList.filter(user => user.id !== id))
      })
  };

  const column = [{
    title: 'Title',
    key: 'title',
    render: (data: UserType) => {
      return data.firstName + ' ' + data.lastName;
    }
  }, {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone'
  }, {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  }, {
    title: 'Enabled',
    key: 'disableAction',
    width: 150,
    render: (data: UserType) => (
      <Switch
        checked={data.status === '1'}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        onChange={(enable) => UserService.enable(data.id, enable)
          .then(() => updateUser(
            data.id, {
            status: enable ? '1' : '0'
          }))}
        defaultChecked
      />
    )
  }, {
    title: 'Action',
    key: 'editAction',
    width: 20,
    render: (data: UserType) => (
      <span>
        <TableOption.Edit onClick={event => setEditModalId(data.id)} />
        <TableOption.Delete onClick={event => deleteUser(data.id)} />
      </span>
    )
  }];

  return (
    <PageContent
      title="User List"
      extra={(<Button type="primary"
        onClick={() => setAddModal(true)} >Create +</Button>)}>
      <Table dataSource={userList} columns={column} />

      {addModal && <Modal title="Create User"
        visible={!!addModal}
        footer={null}
        onCancel={() => setAddModal(false)}>
        <UserForm
          onSubmit={(formData) => UserService.save(formData)
            .then(() => {
              setUserList([
                ...(userList || []),
                { ...formData, status: '1' }
              ]);

              setAddModal(false);
            })} />
      </Modal>}

      {editModalId && <Modal title="Edit User"
        visible={!!editModalId}
        footer={null}
        onCancel={() => setEditModalId(undefined)}>
        <UserForm
          id={editModalId}
          onSubmit={(formData) => UserService.edit(formData)
            .then(() => {
              updateUser(formData.id, formData);
              setEditModalId(undefined);
            })} />
      </Modal>}
    </PageContent >
  );
};

const UserForm: React.FC<{
  id?: number,
  onSubmit: (user: UserType) => void
}> = (props) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<UserType>();

  useEffect(() => {
    if (props.id)
      UserService.get(props.id)
        .then(data => form.setFieldsValue(data))
  }, [props.id])

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      onFinish={(values) => setFormData(values as UserType)}>
      <Form.Item
        label="Name">
        <Input.Group compact>
          <Form.Item
            label="First"
            key="firstName"
            name="firstName"
            rules={[{ required: true }]}>
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            label="Last"
            key="lastName"
            name="lastName"
            rules={[{ required: true }]}>
            <Input placeholder="Last Name" />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Form.Item
        label="Address"
        name="address"
        key="address"
        rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Phone"
        name="phone"
        key="phone"
        rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        key="email"
        rules={[{
          type: 'email',
          message: 'Not a valid E-mail!',
        },
        { required: true }
        ]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        key="password"
        rules={[{ required: true }]}>
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Post"
        name="post"
        rules={[{ required: true }]}>
        <Select placeholder="Select Post">
          <Select.Option value="officer">{'Officer'}</Select.Option>
          <Select.Option value="admin">{'Admin'}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item wrapperCol={{
        xs: {
          span: 6,
          offset: 0,
        },
        sm: {
          span: 6,
          offset: 6,
        },
      }}>
        <Button type="primary" htmlType="submit">
          {props.id ? 'Update' : 'Create'}
        </Button>
      </Form.Item>
      {formData && <Confirm
        label="Submit"
        message="Are you sure you want to submit?"
        onConfirm={() => props.onSubmit({ ...formData, id: (props.id || 0) })}
        onCancel={() => setFormData(undefined)} />}
    </Form>);
}

export default UserList;
