import Axios from 'axios';
import { ResponseMessageType, showToastrResponse, showToastrError } from './helper';
import { BASE_URL } from '../../appProps';
import UserService from '../UserService';

const generateHeaders = (headers?: any) => ({
  'Authorization': UserService.getToken(),
  ...headers
});

const postForFullResponse = async<T extends ResponseMessageType>(
  relativeUrl: string,
  data: any, param?: any) => {
  try {
    const response = await
      Axios.post<T>(
        BASE_URL + relativeUrl,
        data, {
        headers: generateHeaders()
      });

    showToastrResponse(response.data as ResponseMessageType);

    return response.data;
  } catch (error) {
    throw showToastrError(error);
  }
};

const post = async <T>(relativeUrl: string, data: any, param?: any, headers?: any) => {
  try {
    const response = await
      Axios.post<ResponseMessageType>(
        BASE_URL + relativeUrl,
        data, {
        headers: generateHeaders(headers)
      });

    showToastrResponse(response.data);

    return response.data.data as T;
  } catch (error) {
    throw showToastrError(error);
  }
};

const put = async (relativeUrl: string, data: any, param?: any) => {
  try {
    const response = await
      Axios.put<ResponseMessageType>(
        BASE_URL + relativeUrl,
        data, {
        headers: generateHeaders()
      });

    showToastrResponse(response.data);
  } catch (error) {
    throw showToastrError(error);
  }
};

const del = async (relativeUrl: string, id: number) => {
  try {
    const resp = await
      Axios.delete<ResponseMessageType>(
        BASE_URL + relativeUrl, {
        params: {
          id
        },
        headers: generateHeaders()
      });

    showToastrResponse(resp.data);
  } catch (e) {
    throw showToastrError(e);
  }
};

const get = async <T>(relativeUrl: string, params?: any) => {
  try {
    const resp = await
      Axios.get<T>(
        BASE_URL + relativeUrl, {
        params,
        headers: generateHeaders()
      });

    return resp.data;
  } catch (error) {
    throw showToastrError(error);
  }
};

export default {
  postForFullResponse,
  post,
  put,
  del,
  get
}
