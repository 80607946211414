import axios from 'axios';
import {
  showToastrResponse,
  showToastrError,
  ResponseMessageType
} from './_reusable/helper';
import { BASE_URL } from '../appProps';
import Api from './_reusable/Api';

export interface UserInfoType {
  id: number,
  firstName: string,
  lastName: string
};

export interface LoginDetailType {
  usename: string,
  password: string,
};

export interface UserType
  extends UserInfoType {
  "address": string,
  "phone": string,
  "email": string,
  "password"?: string,
  "post": string,
  "imageName": string,
  "status": string
};

export interface ChangePasswordType {
  oldPassword: string,
  password: string
}

const save = async (data: UserType) => {
  return Api.post('/user', data);
};

const edit = async (data: UserType) => {
  Api.put('/user', data);
};

const del = async (id: number) => {
  Api.del('/user', id);
};

const enable = async (id: number, flag: boolean) => {
  Api.post('/user/' + (flag ? 'enable' : 'disable'), {
    id
  });
};

const changePassword = async (data: ChangePasswordType) => {
  Api.post('/user/change-password', data);
};

const get = async (id: number) => {
  const data = await Api.get<UserType[]>('/user/all', {
    id
  });

  return data[0];
};

const getAll = async () => {
  return Api.get<UserType[]>('/user/all');
};

let token: string;

const logout = (history: any) => {
  sessionStorage.clear();
  history.push('/login');
}

const getToken = () => sessionStorage.getItem('token');
const getUserDetail: () => UserType | undefined = () => {
  const userDetailJSON = sessionStorage.getItem('userDetail');
  if (!userDetailJSON) {
    return undefined;
  }

  return JSON.parse(userDetailJSON) as UserType;
}

const getLoggedInUserName: (history: any) => string = (history) => {
  const userDetail = getUserDetail();
  if (userDetail)
    return userDetail.firstName + ' ' + userDetail.lastName;

  history.push('/login');

  return '';
}

interface TokenResponseType extends ResponseMessageType {
  token: string,
  user: UserType
}

const login = async (loginDetail: LoginDetailType) => {
  const response: TokenResponseType = await Api.postForFullResponse<TokenResponseType>(
    '/user/login', loginDetail);
  console.log("response", response);

  sessionStorage.setItem('token', response.token);
  sessionStorage.setItem('userDetail', JSON.stringify(response.user));
}

export default {
  save,
  edit,
  del,
  get,
  getAll,
  getToken,
  enable,
  login,
  changePassword,
  getLoggedInUserName,
  logout
};
