import Api from './_reusable/Api';

export interface SubcategoryInfoType {
  id: number,
  title: string,
  categoryId: number
};

export interface SubcategoryType
  extends SubcategoryInfoType {
  description: string,
  status?: string,
  priority?: number
};

const save = async (data: SubcategoryType) => {
  return Api.post<SubcategoryType>('/subcategory', data);
};

const edit = async (data: SubcategoryType) => {
  return Api.put('/subcategory', data);
};

const del = async (id: number) => {
  Api.del('/subcategory', id);
};

const get = async (id: number) => {
  return Api.get<SubcategoryType>('/subcategory/all', {
    id
  });
};

const getAll = async (
  categoryId?: number,
  searchPhrase?: string,
  status?: number) => {
  return Api.get<SubcategoryType[]>('/subcategory/all', {
    category_id:categoryId,
    search_phrase: searchPhrase,
    status
  });
};

const enable = async (id: number, flag: boolean) => {
  Api.post('/subcategory/' + (flag ? 'enable' : 'disable'), {
    id
  });
};

export default {
  save,
  edit,
  del,
  get,
  getAll,
  enable,
};
