import React from 'react';
import styled from 'styled-components';
import { Form, Input, Button, Checkbox, Card } from 'antd';
import UserService, { LoginDetailType } from '../../../services/UserService';
import { useHistory } from 'react-router-dom';

const LoginPageStyled = styled.div`
  margin: 0px auto;
  margin-top: 20px;
  width: 300px;
  height: 90vh;
`;

const LoginPage: React.FC = () => {
  const history = useHistory();
  const onFinish = (values: any) => {
    UserService.login(values as LoginDetailType).then(() => {
      history.push('/dashboard')
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <LoginPageStyled>
      <Card title="Login" >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              xs: {
                offset: 8
              }
            }}>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </LoginPageStyled>
  );
};

export default LoginPage;
