import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';

export const YoutubePlayer: React.FC<any> = (props) => {
  const [height, setHeight] = useState<number>();
  const [width, setWidth] = useState<number>();
  return (<div
    ref={ref => {
      if (ref) {
        setWidth(ref.getBoundingClientRect().width);
        setHeight(ref.getBoundingClientRect().width * 9 / 16);
      }
    }}
    style={{
      width: '100%',
    }}>
    <ReactPlayer url={props.url} width={width || "100%"} height={height || '100%'} />
  </div>);
};
