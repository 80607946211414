import React, { useEffect } from 'react';

import { Donut, Column, Line } from '@antv/g2plot';
import ArticleService from '../../../services/ArticleService';

function renderChart1(data: any) {
  console.log("chart 1: ", data);
  const chart1 = document.getElementById('chart1');

  if (chart1) {
    const donutPlot = new Donut(chart1, {
      forceFit: true,
      title: {
        visible: true,
        text: 'Article Per Category',
      },
      description: {
        visible: true,
        text: 'Article Per Category',
      },
      radius: 0.8,
      padding: 'auto',
      data,
      angleField: 'value',
      colorField: 'type',
      statistic: {
        visible: true,
        content: {
          value: data.reduce((a: number, v: any) => a + v.value, 0),
          name: 'Total Article',
        },
      },
    });

    donutPlot.render();
  }
}

function renderChart2(data: any) {
  const chart = document.getElementById('chart2');
  if (chart) {
    const columnPlot = new Column(chart, {
      title: {
        visible: true,
        text: 'Viewed/Not Viewed',
      },
      forceFit: true,
      data,
      padding: 'auto',
      xField: 'type',
      yField: 'value',
      meta: {
        type: {
          alias: 'Type',
        },
        value: {
          alias: 'Count',
        },
      },
    });

    columnPlot.render();
  }
}

function renderChart3(data: any) {
  const chart = document.getElementById('chart3');
  if (chart) {
    const columnPlot = new Column(chart, {
      title: {
        visible: true,
        text: 'Daily view count',
      },
      description: {
        visible: true,
        text: 'No of View by Date',
      },
      data,
      xField: 'date',
      yField: 'count',
    });

    columnPlot.render();
  }
}

const DashboardPage: React.FC = () => {
  useEffect(() => {
    ArticleService.getViewStatics().then(dataList => {
      renderChart1(dataList.map(data => ({ type: data.title, value: data.count })));
    });

    ArticleService.getCategoryStatics().then(dataList => {
      renderChart2(dataList.map(data => ({ type: data.type, value: data.count })));
    });

    ArticleService.getMonthlyViewStatics().then(dataList => {
      renderChart3(dataList);
    });
  }, []);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
      <div id="chart1" className="chart-container"></div>
      <div id="chart2" className="chart-container"></div>
      <div id="chart3" className="chart-container" style={{ width: '90%' }}></div>
    </div>
  );
};

export default DashboardPage;
