import { Form, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import React, { useEffect, useState } from 'react';
import CategoryService, { CategoryType } from '../../../services/CategoryService';

interface CategoryDropDownType{
  categoryId?: number,
  onChange?: (value: number) => void,
  label?: string,
  rules?: Rule[],
  wrapperCol?:any,
  mediaType?: string
}

const CategoryDropDown: React.FC<CategoryDropDownType> = (props) => {
  const { onChange, categoryId , ...otherProps} = props;
  const [catList, setCatList] = useState<CategoryType[]>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    CategoryService.getAll(1, props.mediaType)
      .then((list: CategoryType[]) => {
        setLoading(false);
        setCatList(list);
      }).catch(error => setLoading(false));
  }, [props.mediaType]);

  return (
    <Form.Item
      {...otherProps}
      name="categoryId"
      hasFeedback
      validateStatus={loading ? "validating" : ""}>
      <Select
        placeholder={catList ? "Select Category" : loading ? "Loading Category" : "No Category Found"}
        onChange={onChange}
        optionFilterProp="children"
        showSearch
        notFoundContent={null}
        defaultActiveFirstOption={false}>
        {catList &&
          catList.map((cat, i) => (
            <Select.Option key={"cat" + i} value={cat.id} >{cat.title}</Select.Option>))}
      </Select>
    </Form.Item>
  );
};

export default CategoryDropDown;
