import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { Confirm } from '../../_reusable/TableOption';
import UserService from '../../../../services/UserService';
import PageContent from '../../_reusable/PageContent';

const ChangePassword: React.FC = () => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<any>();

  return (
    <PageContent
      title="Change Password">
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={(values) => setFormData(values)}>
        <Form.Item
          label="Old Password"
          name="oldPassword"
          key="oldPassword"
          rules={[{ required: true }]}>
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="password"
          key="password"
          rules={[{ required: true }]}>
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Retype Password"
          name="retypePassword"
          key="retypePassword"
          rules={[{
            required: true,
            validator: function (rule, value) {
              if (value === form.getFieldValue('password')) {
                return Promise.resolve();
              } else {
                return Promise.reject('Password must match');
              }
            }
          }]}>
          <Input.Password />
        </Form.Item>
        <Form.Item wrapperCol={{
          xs: {
            span: 6,
            offset: 0,
          },
          sm: {
            span: 6,
            offset: 6,
          },
        }}>
          <Button type="primary" htmlType="submit">
            Change
        </Button>
        </Form.Item>
        {formData && <Confirm
          label="Submit"
          message="Are you sure you want to change?"
          onConfirm={() => UserService.changePassword(formData)}
          onCancel={() => setFormData(undefined)} />}
      </Form></PageContent>);
}

export default ChangePassword;
