import Api from './_reusable/Api';

export interface FileInfo {
  imageName: string,
  imageUrl: string
};

const uploadImage = async (file: File) => {
  const formData = new FormData();
  formData.append('image', file);

  return Api.post<FileInfo>('/upload', formData, null, {
    'content-type': 'multipart/form-data'
  });
};

export default {
  uploadImage
};
