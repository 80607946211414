import React, { useState } from 'react';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import { Button } from 'antd';

interface DeleteType {
  onClick: (event: any) => void
}

interface EditType {
  onClick: (event: any) => void
}

interface ConfirmType {
  message?: string,
  type?: "danger" | "success" | "info" | "warning" | "primary",
  onConfirm: (event: any) => void,
  onCancel?: () => void,
  label?: string,
  color?: string
}

const Delete: React.FC<DeleteType> = (props) => {
  const [showConfirm, setShowConfirm] = useState<boolean>();
  const onClick = () => {
    setShowConfirm(!showConfirm);
  }

  return null;
}

const Edit: React.FC<EditType> = (props) => {
  const [showConfirm, setShowConfirm] = useState<boolean>();
  const onClick = () => {
    setShowConfirm(!showConfirm);
  }

  return (
    <a href="#" onClick={onClick} style={{ padding: 2 }}>
      <Button type="primary" >Edit</Button>
      {showConfirm &&
        <Confirm
          message="Please confirm to Edit?"
          onConfirm={props.onClick} />}
    </a>
  );
}

export const Confirm: React.FC<ConfirmType> = (props) => {
  const color = props.type && (
    props.type === 'danger' ? '#d9534f' :
      props.type === 'success' ? '##5cb85c' :
        props.type === 'warning' ? '##f0ad4e' :
          props.type === 'info' ? '##5bc0de' : undefined);

  return (
    <Modal visible
      onCancel={props.onCancel}
      footer={[
        <Button key="back" onClick={props.onCancel}>
          Cancel
      </Button>,
        <Button
          key="submit"
          color={props.color}
          style={color && {
            backgroundColor: color,
            borderColor: color
          }}
          type={"primary"}
          onClick={props.onConfirm}>
          {props.label || 'Confirm'}
        </Button>,
      ]} >
      <p>
        {props.message || "Please confirm to proceed?"}
      </p>
    </Modal>
  );
}

export default {
  Delete,
  Edit,
  Confirm
};
