import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch, Table, Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ArticleService, { ArticleType } from '../../../../services/ArticleService';
import CategoryDropDown from '../../_reusable/CategoryDropdown';
import PageContent from '../../_reusable/PageContent';
import SubcategoryDropDown from '../../_reusable/SubcategoryDropdown';
import TableOption from '../../_reusable/TableOption';
import { HOST } from '../../../../appProps';
import ReactPlayer from 'react-player';
import { YoutubePlayer } from '../../../_reusable/YoutubePlayer';
import { parseImageJSON } from './ArticleForm';

const ArticleList: React.FC = () => {
  const [articleList, setArticleList] = useState<ArticleType[]>();
  const [categoryId, setCategoryId] = useState<number>();
  const [subcategoryId, setSubcategoryId] = useState<number>();
  const [searchInputTime, setSearchInputTime] = useState<Date>(new Date());
  const [searchPhrase, setSearchPhrase] = useState<string>();
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    ArticleService.getAll(categoryId, subcategoryId, searchPhrase)
      .then((articleList: ArticleType[]) => {
        setArticleList(articleList);
      }).catch(e => e)
  }, [categoryId, subcategoryId, searchPhrase]);

  const deleteArticle = (id: number) => {
    ArticleService.del(id)
      .then(() => {
        setArticleList(articleList && articleList.filter(article => article.id !== id))
      })
  };

  const updateArticle = (id: number, data: any) => setArticleList(
    articleList && articleList
      .map(article => article.id === id
        ? { ...article, ...data }
        : article))

  const column = [{
    title: 'Image',
    render: (data: ArticleType) => (
      <div style={{maxWidth: '200px'}}>
        {data.category?.mediaType === 'video'
          ? <YoutubePlayer
            url={data.mediaUrl}
            width="100%"
            height="100%"
          />
          : <img style={{ width: '100%' }} src={HOST + parseImageJSON(data.mediaUrl, jsonData=> jsonData.thumbnail)} />}
      </div>
    ),
    key: 'description'
  }, {
    title: 'Description',
    render: (data: ArticleType) => (
      <div style={{maxWidth: '500px'}}>
        <h2>
          {data.title}
        </h2>
        <div
          style={{ maxHeight: 200, overflow: 'hidden' }}
          dangerouslySetInnerHTML={{ __html: data.description || '' }}>
        </div>
      </div>
    ),
    key: 'description'
  }, {
    title: 'Enabled',
    key: 'disableAction',
    width: 20,
    render: (data: ArticleType) => (
      <Switch
        checked={data.status === '1'}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        onChange={(enable) => ArticleService.enable(data.id, enable)
          .then(() => updateArticle(data.id, { status: enable ? '1' : '0' }))}
        defaultChecked
      />
    )
  }, {
    title: 'Action',
    key: 'editAction',
    width: 150,
    render: (data: ArticleType) => (
      <span>
        <TableOption.Edit onClick={event => history.push('/article/' + data.id)} />
        <TableOption.Delete onClick={event => deleteArticle(data.id)} />
      </span>
    )
  }];

  return (
    <PageContent
      title="Article List"
      extra={(<Button type="primary"
        onClick={() => history.push('/article/0')} >Create +</Button>)}>
      <Form
        form={form}
        name="basic"
        onFinish={values=> {
          setSearchPhrase(values.searchPhrase);
        }}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}>
        <CategoryDropDown
          label="Category"
          onChange={(value: number) => {
            setCategoryId(value);
            setSubcategoryId(undefined);
            form.setFieldsValue({
              subcategoryId: undefined
            });
          }}
          wrapperCol={{
            xs: {
              span: 6
            }
          }} />

        <SubcategoryDropDown
          label="Subcategory"
          categoryId={categoryId}
          onChange={value => setSubcategoryId(value)}
          wrapperCol={{
            xs: {
              span: 6
            }
          }} />

        <Form.Item
          label="SearchPhrase"
          name="searchPhrase"
        >
          <Input/>
        </Form.Item>

        <Form.Item wrapperCol={{
          xs: {
            span: 4,
            offset: 0,
          },
          sm: {
            span: 4,
            offset: 4,
          },
        }}>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Form>
      <Table dataSource={articleList} columns={column} />
    </PageContent >
  );
};

export default ArticleList;
