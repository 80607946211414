import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface EditorPropsType {
  value?: string,
  onChange?: (value: string) => void
  [name: string]: any
}

const editorModules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' },
    { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'video'],
    ['clean']
  ],
  clipboard: {
    matchVisual: false,
  }
}

const editorFormats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
]

const Editor: React.FC<EditorPropsType> = (props) => {

  const onChange = (html: string) => {
    console.log('html', html);
    if (props.onChange) {
      if (html.length < 12 &&
        !html.replace('<p>', '').replace('</p>', '').replace('<br>', '').trim()) {
        props.onChange('');
      } else {
        props.onChange(html);
      }
    }
  }

  return (
    <span>
      <ReactQuill
        theme="snow"
        value={props.value || ''}
        onChange={onChange}
        modules={editorModules}
        formats={editorFormats}
      />
    </span>
  );
}

export default Editor;
