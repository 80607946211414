import Api from './_reusable/Api';

export interface CategoryInfoType {
  id: number,
  title: string,
  mediaType?: string,
  priority?: number
};

export interface CategoryType
  extends CategoryInfoType {
  description: string,
  mediaType: string,
  status?: string,
  priority?: number
};

const save = async (data: CategoryType) => {
  return Api.post<CategoryType>('/category', data);
};

const edit = async (data: CategoryType) => {
  Api.put('/category', data);
};

const del = async (id: number) => {
  Api.del('/category', id);
};

const enable = async (id: number, flag: boolean) => {
  Api.post('/category/' + (flag ? 'enable' : 'disable'), {
    id
  });
};

const get = async (id: number) => {
  return Api.get<CategoryType>('/category/id', {
    id
  });
};

const getByNameId = async (id: string) => {
  return Api.get<CategoryType>('/category/id', {
    id
  });
};

const getAll = async (status?: number, mediaType?: string) => {
  return Api.get<CategoryType[]>('/category/all', {
    status,
    mediaType
  });
};

const getAllPublic = async (status?: number) => {
  return Api.get<CategoryType[]>('/public/category/all', {
    status: status
  });
};

export default {
  save,
  edit,
  del,
  get,
  getAll,
  getAllPublic,
  enable,
  getByNameId
};
