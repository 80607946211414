import { Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import SubcategoryService, { SubcategoryType } from '../../../services/SubcategoryService';
import { Rule } from 'antd/lib/form';

interface SubcategoryDropDownType {
  subcategoryId?: number,
  categoryId?: number,
  onChange?: (value: number) => void,
  rules?: Rule[],
  initialValue?: any,
  label?: string,
  wrapperCol?: any,
  [name: string]: any
}

const SubcategoryDropDown: React.FC<SubcategoryDropDownType> = (props) => {
  const { onChange, categoryId, subcategoryId, ...otherProps } = props;
  const [subcatList, setSubcatList] = useState<SubcategoryType[]>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setSubcatList(undefined);
    if (categoryId) {
      setLoading(true);

      SubcategoryService.getAll(categoryId, undefined, 1)
        .then((list: SubcategoryType[]) => {
          setLoading(false);
          setSubcatList(list);
        }).catch(error => setLoading(false));
    }
  }, [categoryId]);

  console.log('trace', categoryId, loading, subcatList);
  const placeholder = !categoryId
    ? "Select a category first"
    : loading
      ? "Loading subcategory"
      : subcatList && subcatList.length
        ? "Select subcategory"
        : "No subcategory found";

  return (
    <Form.Item
      {...otherProps}
      name="subcategoryId"
      hasFeedback
      shouldUpdate
      validateStatus={loading ? "validating" : ""}>
      <Select
        placeholder={placeholder}
        onChange={onChange}
        optionFilterProp="children"
        showSearch
        notFoundContent={null}
        defaultActiveFirstOption={false} >
        {subcatList &&
          subcatList.map((subcat, i) => (
            <Select.Option key={"subcat" + i} value={subcat.id} >
              {subcat.title}
            </Select.Option>))}
      </Select>
    </Form.Item>
  );
};

export default SubcategoryDropDown;
