import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Switch, Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import SubcategoryService, { SubcategoryType } from '../../../../services/SubcategoryService';
import CategoryDropDown from '../../_reusable/CategoryDropdown';
import PageContent from '../../_reusable/PageContent';
import TableOption, { Confirm } from '../../_reusable/TableOption';

const SubcategoryList: React.FC = () => {
  const [subcategoryList, setSubcategoryList] = useState<SubcategoryType[]>();
  const [editModalId, setEditModalId] = useState<number>();
  const [addModal, setAddModal] = useState<boolean>();
  const [categoryId, setCategoryId] = useState<number>();
  const updateSubcategory = (
    id: number,
    data1: any
  ) => subcategoryList &&
    setSubcategoryList(subcategoryList
      .map(data => data.id === id ? { ...data, ...data1 } : data));

  useEffect(() => {
    SubcategoryService.getAll(categoryId)
      .then((list: SubcategoryType[]) => {
        setSubcategoryList(list);
      }).catch(e => e)
  }, [categoryId]);

  const deleteSubcategory = (id: number) => {
    SubcategoryService.del(id)
      .then(() => {
        setSubcategoryList(subcategoryList &&
          subcategoryList.filter(scategory => scategory.id !== id))
      })
  };

  const column = [{
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority'
  }, {
    title: 'Title',
    dataIndex: 'title',
    key: 'title'
  }, {
    title: 'Description',
    dataIndex: 'description',
    key: 'description'
  }, {
    title: 'Enabled',
    key: 'disableAction',
    width: 20,
    render: (data: SubcategoryType) => (
      <Switch
        checked={data.status === '1'}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        onChange={(enable) => SubcategoryService.enable(data.id, enable)
          .then(() => updateSubcategory(data.id, {
            status: enable ? '1' : '0'
          }))}
        defaultChecked
      />
    )
  }, {
    title: 'Action',
    key: 'editAction',
    width: 150,
    render: (data: SubcategoryType) => (
      <span>
        <TableOption.Edit onClick={event => setEditModalId(data.id)} />
        <TableOption.Delete onClick={event => deleteSubcategory(data.id)} />
      </span>
    )
  }];

  return (
    <PageContent
      title="Subcategory List"
      extra={(<Button type="primary"
        onClick={() => setAddModal(true)} >Create +</Button>)}>
      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}>
        <CategoryDropDown
          label="Category"
          onChange={(value: number) => {
            setCategoryId(value);
          }}
          wrapperCol={{
            xs: {
              span: 6
            }
          }} />
      </Form>
      <Table dataSource={subcategoryList} columns={column} />

      {addModal && <Modal title="Create Subcategory"
        visible={!!addModal}
        footer={null}
        onCancel={() => setAddModal(false)}>
        <SubcategoryForm
          onSubmit={subcategory => SubcategoryService.save(subcategory)
            .then((nsubcategory: SubcategoryType) => {
              setSubcategoryList(
                [...(subcategoryList || []), nsubcategory]
              );

              setAddModal(false);
            })} />
      </Modal>}

      {editModalId && <Modal title="Edit Subcategory"
        visible={!!editModalId}
        footer={null}
        onCancel={() => setEditModalId(undefined)}>
        <SubcategoryForm
          id={editModalId}
          onSubmit={subcategory => {
            SubcategoryService.edit(subcategory)
              .then(() => updateSubcategory(subcategory.id, subcategory));
            setEditModalId(undefined);
          }} />
      </Modal>}
    </PageContent >
  );
};

const SubcategoryForm: React.FC<{
  id?: number,
  onSubmit: (subcategory: SubcategoryType) => void
}> = (props) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<SubcategoryType>();

  useEffect(() => {
    if (props.id)
      SubcategoryService.get(props.id)
        .then(data => form.setFieldsValue(data))
  }, [props.id])

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      onFinish={(values) => setFormData(values as SubcategoryType)}>
      <CategoryDropDown
        label="Category"
        rules={[{ required: true }]} />
      <Form.Item
        label="Title"
        name="title"
        key="title"
        rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Priority"
        name="priority"
        key="priority"
        rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        key="description"
        rules={[{ required: true }]}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item wrapperCol={{
        xs: {
          span: 6,
          offset: 0,
        },
        sm: {
          span: 6,
          offset: 6,
        },
      }}>
        <Button type="primary" htmlType="submit">
          {props.id ? 'Update' : 'Create'}
        </Button>
      </Form.Item>
      {formData && <Confirm
        label="Submit"
        message="Are you sure you want to submit?"
        onConfirm={() => props.onSubmit({ ...formData, id: props.id || 0 })}
        onCancel={() => setFormData(undefined)} />}
    </Form>);
}

export default SubcategoryList;
