import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch, Table, Button, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageContent from '../../_reusable/PageContent';
import TableOption from '../../_reusable/TableOption';
import TagService, { TagType } from '../../../../services/TagService';
import Modal from 'antd/lib/modal/Modal';
import { Confirm } from '../../_reusable/TableOption';

const TagList: React.FC = () => {
  const [tagList, setTagList] = useState<TagType[]>();
  const [editModalId, setEditModalId] = useState<number>();
  const [addModal, setAddModal] = useState<boolean>();
  const history = useHistory();
  const [form] = Form.useForm();
  const updateTag = (
    id: number,
    data: any
  ) => tagList &&
    setTagList(tagList
      .map(catData => catData.id === id ? { ...catData, ...data } : catData));

  useEffect(() => {
    TagService.getAll()
      .then((cList: TagType[]) => {
        setTagList(cList);
      }).catch(e => e)
  }, []);

  const deleteTag = (id: number) => {
    TagService.del(id)
      .then(() => {
        setTagList(tagList &&
          tagList.filter(tag => tag.id !== id))
      })
  };

  const column = [{
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority'
  }, {
    title: 'Title',
    dataIndex: 'title',
    key: 'title'
  }, {
    title: 'Description',
    dataIndex: 'description',
    key: 'description'
  }, {
    title: 'Enabled',
    key: 'disableAction',
    width: 20,
    render: (data: TagType) => (
      <Switch
        checked={data.status === '1'}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        onChange={(enable) => TagService.enable(data.id, enable)
          .then(() => updateTag(
            data.id, {
            status: enable ? '1' : '0'
          }))}
        defaultChecked
      />
    )
  }, {
    title: 'Action',
    key: 'editAction',
    width: 150,
    render: (data: TagType) => (
      <span>
        <TableOption.Edit onClick={event => setEditModalId(data.id)} />
        <TableOption.Delete onClick={event => deleteTag(data.id)} />
      </span>
    )
  }];

  return (
    <PageContent
      title="Tag List"
      extra={(<Button type="primary"
        onClick={() => setAddModal(true)} >Create +</Button>)}>
      <Table dataSource={tagList} columns={column} />

      {addModal && <Modal title="Create Tag"
        visible={!!addModal}
        footer={null}
        onCancel={() => setAddModal(false)}>
        <TagForm
          onSubmit={(formData) => TagService.save(formData)
            .then((ntag: TagType) => {
              setTagList([
                ...(tagList || []),
                ntag
              ]);

              setAddModal(false);
            })} />
      </Modal>}

      {editModalId && <Modal title="Edit Tag"
        visible={!!editModalId}
        footer={null}
        onCancel={() => setEditModalId(undefined)}>
        <TagForm
          id={editModalId}
          onSubmit={(formData) => TagService.edit(formData)
            .then(() => {
              updateTag(formData.id, formData);
              setEditModalId(undefined);
            })} />
      </Modal>}
    </PageContent >
  );
};

const TagForm: React.FC<{
  id?: number,
  onSubmit: (tag: TagType) => void
}> = (props) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<TagType>();

  useEffect(() => {
    if (props.id)
      TagService.get(props.id)
        .then(data => form.setFieldsValue(data))
  }, [props.id])

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      onFinish={(values) => setFormData(values as TagType)}>
      <Form.Item
        label="Title"
        name="title"
        key="title"
        rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Priority"
        name="priority"
        key="priority"
        rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        key="description"
        rules={[{ required: true }]}>
        <Input.TextArea />
      </Form.Item>

      <Form.Item wrapperCol={{
        xs: {
          span: 6,
          offset: 0,
        },
        sm: {
          span: 6,
          offset: 6,
        },
      }}>
        <Button type="primary" htmlType="submit">
          {props.id ? 'Update' : 'Create'}
        </Button>
      </Form.Item>

      {formData && <Confirm
        label="Submit"
        message="Are you sure you want to submit?"
        onConfirm={() => props.onSubmit({ ...formData, id: (props.id || 0) })}
        onCancel={() => setFormData(undefined)} />}
    </Form>);
}

export default TagList;
