import React, { useState, useEffect } from 'react';
import { Upload, message } from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { BASE_URL, HOST } from '../../../appProps';
import FileService, { FileInfo } from '../../../services/FileService';

const ImageUploader: React.FC<{
  onChange?: (url: string) => void,
  value?: string,
  placeholder?: string
}> = (props) => {
  const [loading, setLoading] = useState<boolean>();
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => setImageUrl(props.value), [props.value]);

  const validate = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info: any) => {
    if (!validate(info.file)) return;

    setLoading(true);
    FileService.uploadImage(info.file)
      .then((fileInfo: FileInfo) => {
        setLoading(false);
        setImageUrl(fileInfo.imageUrl);
        if (props.onChange)
          props.onChange(fileInfo.imageUrl);
      });
  };

  return (
    <Upload
      accept=".jpg, .png, .gif"
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={() => false}
      onChange={handleChange}
    >
      {imageUrl
        ? <img
          src={HOST + imageUrl}
          alt="avatar"
          style={{ width: '100%' }} />
        : <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div className="ant-upload-text">{props.placeholder || 'Upload'}</div>
        </div>}

    </Upload>
  );
};

export default ImageUploader;
