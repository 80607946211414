import Api from './_reusable/Api';

export interface TagInfoType {
  id: number,
  title: string
};

export interface TagType
  extends TagInfoType {
  description: string,
  status?: string,
  priority?: number
};

const save = async (data: TagType) => {
  return Api.post<TagType>('/tag', data);
};

const edit = async (data: TagType) => {
  Api.put('/tag', data);
};

const del = async (id: number) => {
  Api.del('/tag', id);
};

const enable = async (id: number, flag: boolean) => {
  Api.post('/tag/' + (flag ? 'enable' : 'disable'), {
    id
  });
};

const get = async (id: number) => {
  return Api.get<TagType>('/tag', {
    id
  });
};

const getAll = async () => {
  return Api.get<TagType[]>('/tag/all');
};

export default {
  save,
  edit,
  del,
  get,
  getAll,
  enable
};
