import axios from 'axios';
import {
  showToastrError,
  ResponseMessageType,
  showToastrResponse
} from './_reusable/helper';
import {
  CategoryInfoType
} from './CategoryService';
import {
  SubcategoryInfoType
} from './SubcategoryService';
import Api from './_reusable/Api';
import { TagType } from './TagService';

export interface ArticleType {
  id: number,
  categoryId: number,
  category?: CategoryInfoType,
  subcategoryId?: number,
  subcategory?: SubcategoryInfoType,
  title: string,
  description?: string,
  intro: string,
  status?: string,
  mediaUrl?: string,
  mediaType?: string,
  tags?: TagType[],
  tagIds?: number[],
  createdAt?: string,
  updatedAt?: string,
  priority?: number,
  author?: string,
  publishDate?: string
};

const save = async (data: ArticleType) => {
  return Api.post('/article', data);
};

const edit = async (data: ArticleType) => {
  return Api.put('/article', data);
};

const getAll = async (
  categoryId?: number,
  subcategoryId?: number,
  searchPhrase?: string) => {
  return Api.get<ArticleType[]>("/article/all", {
    category_id: categoryId,
    subcategory_id: subcategoryId,
    search_phrase: searchPhrase
  });
};

const getTrending = async () => {
  return Api.get<ArticleType[]>("/article/trending");
};

const getMainNewsArticle = async (categoryId?: number,) => {
  return Api.get<ArticleType[]>("/public/article/all", { categoryId });
};

interface CategoryStaticsType{
  type: string,
  count: number
}

const getCategoryStatics = async () => {
  return await Api.get<CategoryStaticsType[]>("/article/statics/article-hit");
};

interface ViewStaticsType{
  id: number,
  title: string,
  count: number
}

const getViewStatics = async () => {
  return await Api.get<ViewStaticsType[]>("/article/statics/category");
};

interface MonthlyViewStaticsType{
  date: string,
  count: number
}

const getMonthlyViewStatics = async () => {
  return await Api.get<MonthlyViewStaticsType[]>("/article/statics/monthly");
};

const getPublicNewsArticle = async (params: {
  category_id?: number,
  subcategory_id?: number,
  priority?: number[],
  page_no?: number,
  count?: number,
  media_type?: string
}) => {
  const data = await Api.get<any>("/public/article/all", params);

  return data.rows as ArticleType[];
};

const getTrendingNewsArticle = async (params: {
  category_id?: number,
  subcategory_id?: number,
  count?: number
}) => {
  const data = await Api.get<any>("/public/article/trending", params);

  return data.rows as ArticleType[];
};

const get = async (id: number) => {
  return Api.get<ArticleType>("/article", {
    id
  });
};

const getPublic = async (id: number) => {
  return Api.get<ArticleType>("/public/article", {
    id
  });
};

const del = async (id: number) => {
  try {
    const resp = await
      axios.delete<ResponseMessageType>(
        '/article', {
        params: {
          id
        }
      });

    showToastrResponse(resp.data);
  } catch (e) {
    throw showToastrError(e);
  }
};

const enable = async (id: number, enable: boolean) => {
  Api.post('/article/' + (enable ? 'enable' : 'disable'), {
    id
  });
};

export default {
  save,
  edit,
  get,
  getPublic,
  getAll,
  getTrending,
  del,
  enable,
  getMainNewsArticle,
  getPublicNewsArticle,
  getCategoryStatics,
  getViewStatics,
  getMonthlyViewStatics,
  getTrendingNewsArticle
};
