import { AppstoreOutlined, FileAddOutlined, SettingOutlined, DashboardOutlined, VideoCameraOutlined, PlaySquareOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
const { SubMenu } = Menu;

const AdminSideBar: React.FC = (props) => {
  const history = useHistory();
  const handleClick = ({ key }: { key: string }) => {
    switch (key) {
      case '1':
        history.push("/dashboard");
        break;
      case '3':
        history.push("/user/list");
        break;
      case '21':
        history.push("/category/list");
        break;
      case '22':
        history.push("/subcategory/list");
        break;
        case '23':
          history.push("/tag/list");
          break;
      case '24':
        history.push("/article/list");
        break;
    }
  };

  return (
    <Menu
      theme="light"
      onClick={handleClick}
      defaultSelectedKeys={['1']}
      defaultOpenKeys={['sub1']}
      mode="inline"
    >
      <Menu.Item key="1" icon={<DashboardOutlined />}>
        Dashboard
      </Menu.Item>
      <SubMenu key="2" icon={<FileAddOutlined />} title="Article">
        <Menu.Item key="21">Categories</Menu.Item>
        <Menu.Item key="22">Subcategories</Menu.Item>
        <Menu.Item key="23">Tags</Menu.Item>
        <Menu.Item key="24">Articles</Menu.Item>
      </SubMenu>
      <Menu.Item key="3" icon={<UserOutlined />}>
        Users
      </Menu.Item>
    </Menu>
  );
};

export default AdminSideBar;