import React, { useState, useEffect } from 'react';
import { Upload, message, Button } from 'antd';
import Icon, { PlusOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { BASE_URL, HOST } from '../../../appProps';
import FileService, { FileInfo } from '../../../services/FileService';
import { Spinner } from '../../_reusable/Spinner';

const FileUploader: React.FC<{
  onChange?: (url: string) => void,
  value?: string,
  placeholder?: string
}> = (props) => {
  const [loading, setLoading] = useState<boolean>();
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => setImageUrl(props.value), [props.value]);

  const validate = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/GIF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info: any) => {
    setLoading(true);
    FileService.uploadImage(info.file)
      .then((fileInfo: FileInfo) => {
        setLoading(false);
        setImageUrl(fileInfo.imageUrl);
        if (props.onChange)
          props.onChange(fileInfo.imageUrl);
      }).catch(e=> setLoading(false));
  };

  return (
    <Upload
      accept=".pdf"
      showUploadList={false}
      beforeUpload={file => false}
      onChange={handleChange}>
      <Button loading={loading}>
        <UploadOutlined /> Click to Upload
    </Button>
      {imageUrl && <span> <a target="blank" href={HOST + imageUrl}> View / Download PDF</a></span>}
    </Upload>
  );
};

export default FileUploader;
