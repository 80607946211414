import { message } from 'antd';

export interface ResponseMessageType {
  message: string,
  data: any
}

export const showToastrResponse = function (response: ResponseMessageType) {
  message.success(response.message);
};

export const showToastrError = function (error: any) {
  if (error.response && error.response.data && error.response.data.message) {
    message.error(error.response.data.message);
  } else {
    message.error(error.message);
  }
  return error;
};
